import Vue from 'vue'
import App from './App.vue'
import * as config from '@/config'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import * as echarts from 'echarts'

import routes from './router'
import VueRouter from 'vue-router';
import * as $localStorage from '@/utils/localStorage'
import Service from '@/utils/ajax';
import * as Time from '@/utils/time';
import * as StrTool from '@/utils/str';
Vue.prototype.$config = config;
Vue.prototype.$localStorage = $localStorage;
Vue.prototype.$http = Service;
Vue.prototype.$time = Time;
Vue.prototype.$strTool = StrTool;
Vue.prototype.$echarts = echarts;

Vue.use(VueRouter)
Vue.use(ElementUI);

Vue.config.ignoredElements = ['wx-open-launch-weapp']

Vue.config.productionTip = false
const _routes = new VueRouter({
  routes,
  // mode: 'history'
});

_routes.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }

  next()
})

new Vue({
  router: _routes,
  el: '#app',
  render: h => h(App)
})
