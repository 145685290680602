import factory from './factory.router'
import ryProbe from './ryProbe.router'
import introduce from './introduce.router'

const routes = [
	{
		path: '/',
		name: 'Index',
		component: () => import('@/pages/index')
	},
	factory,
	ryProbe,
	introduce,
	{
		path: '/wechatWebView',
		name: 'WechatWebView',
		component: () => import('@/pages/wechatWebView'),
		meta: {
			title: '公告详情'
		}
	},
	{
		path: '*',
		name: 'NotFount',
		component: () => import('@/pages/notFount'),
		meta: {
			title: '404'
		}
	},
	{
		path: '/videoCourse',
		name: 'VideoCourse',
		component: () => import('@/pages/videoCourse'),
		meta: {
			title: '安装教程'
		}
	}
]
export default routes;