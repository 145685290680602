const introduce = {
	path: '/introduce',
	redirect: '/introduce/oxySnap',
	name: 'Introduce',
	component: () => import('@/Layout'),
	meta: {
		title: '使用教程',
	},
	children: [
		{
			path: 'oxySnap',
			component: () => import('@/pages/introduce/oxySnap'),
			name: 'OxySnap',
			meta: { title: '随手测使用教程' },
		},
	],
};
export default introduce;